<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="calculator" />
				<span>财务</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 回单管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="6">
						<a-form-item :label="`发生时间：`">
							<a-range-picker  :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" :placeholder="['开始时间', '结束时间']" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" @click="getPageList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">回单列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showDetail()">
							详情
						</a-button>
					</div>
				</div>

				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange"
				 :pagination="{total: total_page * 10}" :data-source="pageList" rowKey="acc_id" size="small">
					<span slot="status" slot-scope="status">
						<a-tag color="red" v-if="status == 0">
							暂停
						</a-tag>
						<a-tag color="blue" v-if="status == 1">
							启动
						</a-tag>
					</span>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import http from '../http'

	const columns = [{
			title: "ID",
			dataIndex: "acc_id",
			width: 60
		},
		{
			title: "回单编号",
			dataIndex: "trade_no",
		},
		{
			title: "运营商名称",
			dataIndex: "corp_name",
		},
		{
			title: "所属区域",
			dataIndex: "region",
		},
		{
			title: "清机总金额",
			dataIndex: "in_amount",
		},
		{
			title: "取币总金额",
			dataIndex: "out_amount",
		},
		{
			title: "微信汇款(元)",
			dataIndex: "wx_amount",
		},
		{
			title: "支付宝汇款(元)",
			dataIndex: "ali_amount",
		},
		{
			title: "发生时间",
			dataIndex: "update_time",
		},
	];
	export default {
		data() {
			return {
				expand: false,
				columns,
				searchData: {
					page: 1,
					start_date: '',
					end_date: ''
				},
				pageList: [],
				total_page: 0,
				selectedRowKeys: [],
				selectedRowDataList: [],
				pickerKey: Math.random()
			}; 
		},
		mounted() {
			this.getPageList()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			},
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			showDetail(){

			},
			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					start_date: '',
					end_date: ''
				}
				this.getPageList();
			},
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},
			search() {
				this.searchData.page = 1
				this.getPageList()
			},
			handleTableChange(pagination, filters, sorter) {
				this.searchData.page = pagination.current
				this.getPageList();
			},
			getPageList() {
				http({
					method: 'post',
					url: '/api.account.receiptlist/',
					data: this.searchData
				}).then((res) => {
					this.pageList = res.data.list
					this.total_page = res.data.total_page
				})
			},
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 33.33%;
				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: center;

			&>div {
				width: 33.33%;
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				height: 300px;
				border-right: 1px solid #e8e8e8;
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
